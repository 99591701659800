<template>
  <v-container>
    <!-- upload file drag&drop -->
    <div class="drop-area">
      <div class="drop">
        <div v-show="$refs.drop && $refs.drop.dropActive" class="drop-active">
          <h3>Drop files to upload</h3>
        </div>
        <div class="upload-btn">
          <file-upload
            class="btn btn-primary"
            :multiple="false"
            :drop="true"
            :drop-directory="false"
            accept="image/jpeg"
            extensions="jpg,jpeg"
            v-model="input_file.drop_files"
            @input-filter="dropFileSelect"
            ref="drop"
          >
          </file-upload>
        </div>
      </div>
    </div>

    <!-- Image -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="8" xl="5">
        <v-card flat>
          <TwentyTwenty
            class="justify-center"
            ref="twentytwenty"
            :offset="preview_image.slide_offset"
            :before="preview_image.before"
            beforeLabel=""
            :after="preview_image.after"
            afterLabel=""
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- フォーム -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="8" xl="5">
        <v-form ref="form" v-model="valid">
          <!-- file input -->
          <v-file-input
            class="mb-1"
            :label="$t('image_converter.message.file_input')"
            accept="image/jpeg"
            v-model="input_file.file"
            prepend-icon="mdi-image"
            truncate-length="99"
            required
            :messages="input_file.message"
            :clearable="false"
            :rules="filesInputRules"
            :disabled="is_loading"
            :loading="is_loading"
            :error-messages="input_file.error_msg"
            @focus="alert.visible = false"
            @change="clickFileSelect"
          ></v-file-input>

          <!-- Upload Button -->
          <v-btn
            color="primary"
            large
            :disabled="!valid || conv_image_info != null"
            :loading="is_loading"
            @click="uploadImage"
          >
            {{ $t("image_converter.upload") }}
            <v-icon right> mdi-cloud-upload </v-icon>
          </v-btn>
          <!-- Save Button-->
          <v-btn
            v-if="conv_image_info"
            color="secondary"
            class="ml-2"
            large
            @click="saveImage"
          >
            {{ $t("image_converter.save") }}
            <v-icon right> mdi-content-save-outline </v-icon>
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <!-- クレジット -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <div class="text-center" v-html="$t('image_converter.site_credit')"></div>
      </v-col>
    </v-row>

    <!-- 説明文 -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <p>{{ $t('image_converter.message.information') }}</p>
      </v-col>
    </v-row>

    <!-- 横浜浮世絵サンプル -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="5" lg="4" xl="3">
        <v-card
          class="mx-auto"
          max-width="344"
        >
          <v-img
            :src="require('@/assets/Bb1-03-027-002.jpg')"
            contain
          />
          <v-card-title>
            東海道名所之内横浜風景
          </v-card-title>
          <v-card-subtitle>
            貞秀画 万延元年(1860)
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              href="https://www.city.yokohama.lg.jp/kurashi/kyodo-manabi/library/shuroku/memory/memory4/memory4-8.html"
              target="_blank"
              text>
              More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="4" xl="3">
        <v-card
          class="mx-auto"
          max-width="344"
        >
          <v-img
            :src="require('@/assets/Bb1-03-028.jpg')"
            contain
          />
          <v-card-title>
            神名川横浜新開港図
          </v-card-title>
          <v-card-subtitle>
            貞秀画 万延元年(1860)
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              href="http://www.kaikou.city.yokohama.jp/document/picture/02_02.html"
              target="_blank"
              text>
              More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- 注意事項 -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <v-alert dense type="info" color="primary" outlined>
          <span v-html="$t('image_converter.message.attention')"></span>
        </v-alert>
      </v-col>
    </v-row>

    <!-- 画像クレジット -->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <div class="text-center text-caption">{{ $t('image_converter.image_credit') }}</div>
      </v-col>
    </v-row>


    <!-- アラート -->
    <v-snackbar
      v-model="alert.visible"
      color="error"
      transition="scroll-y-transition"
      timeout="-1"
      top
    >
      {{ $t(alert.text) }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs">
          <v-icon @click="alert.visible = false">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.twentytwenty-container {
  z-index: 4;
}
.twentytwenty-overlay {
  background: rgb(0,0,0,0) !important;
}
.upload-btn {
  display: none;
}
.drop-area .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.drop-area .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
form .v-input .v-label,
form .v-input .v-icon,
form .v-input .v-messages {
  color: #002773
}
</style>

<script>
import FileUpload from "vue-upload-component/dist/vue-upload-component.part";
import { saveAs } from "file-saver";
import ImageUtil from "../../services/imageUtil";
import "vue-twentytwenty/dist/vue-twentytwenty.css";
import TwentyTwenty from "vue-twentytwenty";

// 画像最大サイズ10MB
const MAX_SIZE = 10 * 1024 * 1024;

export default {
  name: "ImageConverter",
  components: {
    FileUpload,
    TwentyTwenty,
  },
  mounted() {
    // API設定
    this.api = process.env.VUE_APP_API;
    this.axios.defaults.headers.common["x-api-key"] =
      process.env.VUE_APP_APIKEY;
    this.input_file.message = this.$t("image_converter.message.howto");
  },
  data: () => ({
    alert: {
      visible: false,
      text: "",
    },
    valid: false,
    is_loading: false,
    conv_image_info: null,
    // 入力ファイル
    input_file: {
      file: null,
      drop_files: [],
      error_msg: null,
      message: null,
    },
    // プレビュー表示とファイル送信用
    preview_image: {
      before: require("@/assets/sample_before.jpg"),
      after: require("@/assets/sample_after.png"),
      slide_offset: 0.5,
      file_name: "",
      blob: null,
    },
  }),
  computed: {
    // 入力ファイルルール
    filesInputRules() {
      return [
        // 未入力
        (value) => !!value || this.$t("image_converter.message.file_required"),
        // フォーマットエラー
        (value) =>
          !value ||
          !value.name.lastIndexOf(".") ||
          value.type == "image/jpeg" ||
          this.$t("image_converter.message.format_error"),
        // 最大サイズ
        (value) =>
          !value ||
          value.size <= MAX_SIZE ||
          this.$t("image_converter.message.size_error"),
      ];
    },
  },
  methods: {
    // ファイルドロップ時
    dropFileSelect(newFile, oldFile, prevent) {
      this.$gtag.event("ファイルドロップ", {
        event_category: "ファイル選択",
        event_label: "ファイルドロップ選択",
      });
      if (newFile && !this.is_loading) {
        if (!/\.(jpeg|jpe|jpg)$/i.test(newFile.name)) {
          return prevent();
        }
        if (newFile.error) {
          return prevent();
        }
        this.input_file.file = newFile.file;
        this.displayUploadImage(newFile.file);
      }
    },
    // ファイル選択時
    clickFileSelect(file) {
      this.$gtag.event("ファイル欄クリック", {
        event_category: "ファイル選択",
        event_label: "ファイル欄クリック選択",
      });
      this.input_file.file = null;
      this.displayUploadImage(file);
    },
    // アップロード画像プレビュー
    async displayUploadImage(file) {
      this.input_file.message = null;
      if (file == undefined || file == null) {
        return;
      }
      if (file.size > MAX_SIZE) {
        return;
      }
      if (file.name.lastIndexOf(".") <= 0) {
        return;
      }
      if (file.type != "image/jpeg") {
        return;
      }

      this.alert.visible = false;
      this.conv_image_info = null;
      this.input_file.error_msg = null;
      this.preview_image.fileName = null;

      this.input_file.message = this.$t("image_converter.message.howto");

      try {
        // ファイルリサイズ
        const comp_file = await ImageUtil.getCompressImageFileAsync(file);
        this.$refs.twentytwenty.containerStyle = {};
        this.$refs.twentytwenty.slideOffset = 1.0;
        this.preview_image.before = await ImageUtil.getDataUrlFromFile(
          comp_file
        );
        this.preview_image.after = this.preview_image.before;
        this.preview_image.fileName = file.name;
        this.preview_image.blob = comp_file;
      } catch (e) {
        this.input_file.error_msg = this.$t(
          "image_converter.message.format_error"
        );
        return;
      }
    },
    // 変換画像表示
    displayConvertImage(data_b64) {
      if (data_b64 !== undefined && data_b64 !== null) {
        this.preview_image.after = "data:image/png;base64," + data_b64;
      }
    },
    // アップロード
    uploadImage() {
      this.$gtag.event("ファイルアップロード", {
        event_category: "アップロード",
        event_label: "アップロードボタンクリック",
      });

      this.alert.visible = false;
      // this.$refs.form.reset()
      // this.$refs.observer.validate()
      if (!this.preview_image.blob) {
        return;
      }
      // リクエスト情報
      this.is_loading = true;
      let form_data = new FormData();
      form_data.append(
        "file",
        new File([this.preview_image.blob], this.preview_image.blob.name, {
          type: this.preview_image.blob.type,
        })
      );

      // リクエスト
      this.axios
        .post(this.api + "/convert", form_data)
        .then((response) => {
          // 成功レスポンス
          // console.log(response);
          this.conv_image_info = response.data;
          this.displayConvertImage(this.conv_image_info.data);
          this.is_loading = false;
          this.slideAnimation(
            this.$refs.twentytwenty.slideOffset,
            0.0,
            -0.01,
            5
          );
        })
        .catch((error) => {
          // 失敗レスポンス
          console.log(error);
          if (error.response) {
            if (error.response.status >= 500) {
              this.show_alert("image_converter.message.server_error");
            } else if (error.response.status >= 400) {
              // console.log(error.response.data);
              this.show_alert("image_converter.message.format_error");
            }
          } else {
            this.show_alert("image_converter.message.server_error");
          }
          this.is_loading = false;
        });
    },
    // スライドバーアニメーション
    slideAnimation(
      start_offset = 1.0,
      end_offset = 0.0,
      step = -0.01,
      interval = 5
    ) {
      let offset = start_offset;
      let timer;

      const animate = () => {
        offset += step;
        if (offset > end_offset) {
          timer = setTimeout(() => {
            animate();
          }, interval);
        } else {
          clearTimeout(timer);
          timer = null;
        }
        this.$refs.twentytwenty.slideOffset = offset;
      };
      animate();
    },
    // 保存
    saveImage() {
      this.$gtag.event("ファイル保存", {
        event_category: "保存",
        event_label: "保存ドボタンクリック",
      });
      // ファイルをダウンロード(文字コードはutf-8固定)
      let data = this.conv_image_info.data;
      let filename = this.conv_image_info.file_name;
      let data_blob = this.toBlob(data, this.conv_image_info.mine_type);
      if (data_blob !== undefined && data_blob !== null) {
        saveAs(data_blob, filename);
      }
    },
    // base64データからBlobへ変換
    toBlob(base64, mime_ctype) {
      var bin = atob(base64.replace(/^.*,/, ""));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      // Blobを作成
      try {
        var blob = new Blob([buffer.buffer], {
          type: mime_ctype,
        });
      } catch (e) {
        return false;
      }
      return blob;
    },
    // アラート表示
    show_alert(msg_id) {
      this.alert.text = msg_id;
      this.alert.visible = true;
    },
    // スクロール
    onMenuClick(e, item) {
      // console.log("onMenuClick");
      e.stopPropagation();

      if (item.to || !item.href) return;

      this.$vuetify.goTo(item.href.endsWith("#") ? 0 : item.href);
    },
  },
};
</script>
