import imageCompression from "browser-image-compression";

export default {
  // 画像リサイズ
  async getCompressImageFileAsync(file, options={}) {
    const defaults = {
      maxSizeMB: 3, // 最大ファイルサイズ
      maxWidthOrHeight: 960, // 画像長辺最大px
      checkOrientation: false
      // initialQuality: 0.8
    };
    const assign = Object.assign;
    let settings = assign({}, defaults, options);
    try {
      // 圧縮画像の生成
      return await imageCompression(file, settings);
    } catch (error) {
      console.error("getCompressImageFileAsync is error", error);
      throw error;
    }
  },
  // FileオブジェクトからURL取得
  async getDataUrlFromFile(file) {
    try {
      return await imageCompression.getDataUrlFromFile(file);
    } catch (error) {
      console.error("getDataUrlFromFile is error", error);
      throw error;
    }
  }
};
